<!-- =========================================================================================
  File Name: FullPage.vue
  Description: Full page layout
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="layout--full-page">
    <video autoplay muted loop class="background-video">
      <source
        src="@/application/assets/videos/background.mp4"
        type="video/mp4"
      />
    </video>
    <transition
      enter-active-class="animated bounceInUp"
      leave-active-class="animated zoomOut"
    >
      <router-view></router-view>
    </transition>
    <LanguageOptions />
    <div class="app-version">
      <p>
        {{ version }}
        <b>BETA</b>
      </p>
    </div>
  </div>
</template>

<script>
import LanguageOptions from "@/application/components/LanguageOptions";
import { version } from "@/../package.json";
export default {
  components: { LanguageOptions },
  data() {
    return {
      version
    };
  }
};
</script>

<style lang="scss" scoped>
.background-video {
  position: fixed;
  // right: 0;
  // bottom: 0;
  // width: 100%;
  // height: 100%;
  top: -30%;
  object-fit: cover;
  object-position: center;
  opacity: 0.03;
  filter: alpha(opacity=2); /* For IE8 and earlier */
}

.app-version {
  position: absolute;
  left: 100vw;
  top: 100vh;
  transform: translate(-100%, -100%);
  font-size: 9px;
}
</style>
